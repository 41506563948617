import React from 'react';
import { Dropdown } from "rsuite";

import { useTranslate } from 'react-polyglot';

import './floatingButton.scss';
import {ReactSVG} from "react-svg";

function FloatingButton() {
    const t = useTranslate();

    return (
        <div className={'floating-icon'}>
            <Dropdown className={'colorButton'} placement={'topEnd'}
                      renderTitle={() => {
                          return <ReactSVG src="/images/help-icon-bottom.svg"/>
                      }}
            >
                <Dropdown.Item onSelect={ () =>{
                    window.open('https://camonapp.zendesk.com/hc/es', '_blank');
                    window.focus();
                }}>
                    {t('help.goToSupport')}
                </Dropdown.Item>
            </Dropdown>
        </div>
    )
}

export default FloatingButton;
