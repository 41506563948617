import React, {Fragment, useState} from 'react';
import {Button} from "rsuite";
import {useTranslate} from "react-polyglot";
import imageNoHover from './exp-plane-tracking.png'
import imageHover from './exp-plane-tracking-hover.png'

// THIS COMPONENT CAN BE SEEN WHEN LOGGED IN AS FREELANCE AND FREELANCE PAID USERS
const StudioOptions = ({
                               setStep,
                               step,
                               setTitle,
                               setHideExperienceOptions,
                               hover,
                               setHover,
                               experienceRoad,
                               setExperienceRoad,
                               isEditor,
                               isFreelanceX
                           }) => {
    const t = useTranslate();
    const goToFaceExps = () => {
        setExperienceRoad({...experienceRoad, ...{experience_type: '4'}});
        setTitle('Face Experience');
        setHideExperienceOptions(false);
        setStep(['image_tracking']);
    };
    setTitle(t('studioNewFlow.title'));
    const [hoverImage, setHoverImage] = useState(false);
    return (
        <Fragment>
            <div className={'new-experience-type-container'}>
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({experience_type: 'image_tracking'});
                }} onMouseLeave={() => {
                    setHover({experience_type: ''});
                }} onClick={() => {
                    setExperienceRoad({...experienceRoad, ...{ 
                        world_tracking_type: 'web',
                        experience_type: 'ImmersionWeb'
                     }});
                    setTitle(t('studioNewFlow.interactiveExperince'));
                    setHideExperienceOptions(false);
                    setStep(['interactive_experience_immersionWeb']);
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.experience_type === 'image_tracking' ?
                                <img src="/images/exp-360-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/exp-360.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                            {t('studioNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'image_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('studioNewFlow.interactiveExperince')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('studioNewFlow.interactiveExperinceDescription')}
                        </p>
                    </div>
                </div>
                { !isFreelanceX() &&
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({experience_type: 'world_tracking'});
                }} onMouseLeave={() => {
                    setHover({experience_type: ''});

                }} onClick={() => {
                    setExperienceRoad({...experienceRoad, ...{experience_type: 'model_3d'}});
                    setHideExperienceOptions(false);
                    setTitle(t('studioNewFlow.model3d'));
                    setStep(['model_3d']);
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.experience_type === 'world_tracking' ?
                                <img src="/images/illustration-world-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/illustration-world.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'}>
                            {t('studioNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('studioNewFlow.model3d')} </span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('studioNewFlow.model3dDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>
                    </div>
                </div>
                }
                <div
                    className={'new-experience-type disabled'}
                >
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.experience_type === 'plane_tracking' ?
                                <img src="/images/illustration-world-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/illustration-world.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: ''}}>
                            {t('studioNewFlow.comingSoon')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'plane_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('studioNewFlow.planeTracking')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('studioNewFlow.planeTrackingDescription')}
                        </p>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default StudioOptions;