import React, { Fragment, memo, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Button } from '@rmwc/button';

import { SectionContainer, ImgsContainer, Title, Paragraph } from './styles';

import '@material/button/dist/mdc.button.css';
import './confirmation.css';
import RegisterHeader from '../../components/headers/RegisterHeader';

const Confirmation = memo(function Confirmation(props) {
    const [show, setShow] = useState(false);


    useEffect(() => {
        if (props.path === '/confirmationSend' || props.path === '/resetSend' || props.path === '/resetOk') {
            setShow(true);
        }
    }, [props.path])

    return (
        <Fragment>
            <RegisterHeader />
            <SectionContainer show={show} className="confirmation-container with-header">
                <ImgsContainer>
                    <div className="old">
                        <ReactSVG src="/images/old2.svg" />
                    </div>
                    <div className="new">
                        <ReactSVG src="/images/new2.svg" />
                    </div>
                </ImgsContainer>
                <Title>
                    {props.title}
                </Title>
                <Paragraph>
                    {props.p}
                </Paragraph>
                <Button
                    type="submit"
                    label={props.button}
                    raised
                    className={'login-button'}
                    onClick={props.action}
                />
            </SectionContainer>
        </Fragment>
    )
})


export default Confirmation
